import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import fondo from './img/doodles.svg'

export default function Calendar () {
  return (
    

          <section className=" py-6 px-4 bg-cover lg:bg-center verdeclaro"  style={{backgroundImage: `url('${fondo}')`}}>
      <div className="flex-center text-center py-3">
      <h2 className=" mb-4 leading-tight font-heading  titulos">Calendario Escolar Mensual</h2>
      </div>
        <div className="flex flex-wrap items-center text-center lg:text-left -mx-2 ">

          <div className="lg:w-1/2 px-2 lg:pl-16 mt-4 lg:mt-0 md:w-auto ">
          <iframe src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=America%2FMexico_City&amp;src=Y18wNWcwMWlyc3U2N2czOWhwMWJxaGJkdDdiY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&amp;color=%237986CB&amp;showTz=0&amp;showCalendars=0&amp;showTabs=0&amp;showPrint=0&amp;showDate=0&amp;showNav=0&amp;showTitle=0" width="100%" height="500" frameborder="0" scrolling="no" className="   "></iframe>        
          </div>
          <div className="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0">
            <h2 className="text-4xl px-4 mb-4 leading-tight font-heading semititulos">Información de interes</h2>
            
            <div className="flex flex-wrap max-w-5xl -mx-4 md:mx-auto -mb-8">

            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="h-full p-6  rounded border-t-4 border-blue-700 shadow-md verde hover:bg-white" >
                <Link className="py-10 semititulos" to="/clases">Clases virtuales</Link>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 ">
            <div className="h-full p-6  rounded border-t-4 border-blue-700 shadow-md verde hover:bg-white" >
            <Link className="py-10 semititulos" to="/presencial">Regreso a clases presencial</Link>
        
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 ">
              <div className="h-full p-6  rounded border-t-4 border-blue-700 shadow-md verde hover:bg-white">
              <Link className="py-10 semititulos" to="/">Estaregia de habitos de salud</Link>
              </div>
            </div>
            <div className="w-full md:w-1/2 px-4 mb-8 ">
            <div className="h-full p-6 bg-white rounded border-t-4 border-blue-700 shadow-md verde hover:bg-white" >
            <Link className="py-10 semititulos" to="/">Activacion fisica</Link>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-4 mb-8 ">
          <div className="h-full p-6 bg-white rounded border-t-4 border-blue-700 shadow-md verde hover:bg-white" >
          <Link className="py-10 semititulos" to="/intervencion">Departamento de intervención educativa</Link>
            </div>
          </div>
          </div>
          </div>
        </div>
      </section>
        
    )
}