import React from 'react'
import {Link} from 'react-router-dom'

import {IoLogoFacebook, IoLogoInstagram, IoLogoYoutube, IoLogoTwitter, IoLogoLinkedin} from 'react-icons/io'
import {IconContext} from 'react-icons'


export default () => (
    <nav className="flex items-center justify-between flex-wrap azul p-6">
  <div className="flex items-center flex-shrink-0 text-white mr-6">
    <Link className="font-semibold text-xl tracking-tight px-2 py-1 hover:text-blue-900 rounded hover:bg-white" to="/">Inicio</Link>
  </div>
  <div className="block lg:hidden">
    <button className="flex items-center px-3 py-2 border rounded text-teal-200 border-teal-400 hover:text-white hover:border-white">
      <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
    </button>
  </div>
  <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
    <div className="text-sm lg:flex-grow">
      <Link to="/nosotros" className="block mt-4 lg:inline-block lg:mt-0 semititulosblanco rounded text-teal-200 px-2 py-1 hover:text-blue-900 hover:bg-white mr-4">
        Nosotros
      </Link>
      <Link to="/oferta" className="block mt-4 lg:inline-block lg:mt-0 semititulosblanco rounded text-teal-200 px-2 py-1 hover:text-blue-900 hover:bg-white mr-4">
        Oferta Educativa
      </Link>
      <Link to="/comunidad" className="block mt-4 lg:inline-block lg:mt-0  text-teal-200 hover:text-white">
        
      </Link>
    </div>
    <div>
    <span className="parrafo">
    <IconContext. Provider value={{className:"inline-block mr-2 text-4xl blancoicono"}} >
    <a target="blank" href="https://www.facebook.com/Liceo-Pierre-Bourdieu-100149768347495"><IoLogoFacebook /></a> <IoLogoYoutube /> 
    </IconContext.Provider></span>
  </div>
  </div>
</nav>
)