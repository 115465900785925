import React from 'react'


import Nav from './Nav'
import Navdos from './Navdos'
import Banner from './Banner'


import Arte from './Arte'
import IntermedioBanner from './IntermedioBanner'
import Instalaciones from './Instalaciones'
import Player from './Player'

import Ruta from './Ruta'
import Prueba from './Pruebas'


import Modelo from './Modelo'
import CardNiveles from './CardNiveles'
import Ventajas from './Ventajas'

import CardHover from './CardHover'


import Calendar from './Calendar'
import Contacto from './Contacto'

import Map from './mapa'
import credentials from '../credentials'

const mapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKey}`


export default () => {
 return (
     <div>
     <Banner />
      <div className="text-center ">
      <Modelo />
      </div>

      <CardNiveles />
      <Calendar />

        <Arte />
      <Prueba />
      <Contacto />

      <Map isMarkerShown
      googleMapURL= {mapURL}
      containerElement= {<div style={{height: '400px'}} />}
      mapElement= {<div style={{height:'100%'}} />}
      loadingElement= {<p>Cargando</p>}
    />
      <Ruta />

     
     </div>

 )
}