import React from 'react'
import {Link } from 'react-router-dom'

export default () => (
    <section className="py-8 px-4 azul">
    <div className=" mb-6 text-center leading-tight font-heading titulosblanco   py-2">Cuotas </div>

        <div className="flex flex-wrap -mx-4 text-2xl text-center">

          <div className="w-full md:w-1/3 p-4 mb-4 md:mb-0">
            
            <h3 className="text-xl text-white my-2 font-heading semititulosblanco">PREESCOLAR</h3>
            <p className="text-gray-600 leading-relaxed parrafo px-8">Cuota mensual: $2,200.00</p>
          </div>
          <div className="w-full md:w-1/3 p-4 mb-4 md:mb-0 md:border-l">

          <h3 className="text-xl text-white  my-2 font-heading semititulosblanco">PRIMARIA</h3>
          <p className="text-gray-600 leading-relaxed parrafo px-8">Cuota mensual: $2,500.00</p>
          </div>
          <div className="w-full md:w-1/3 p-4 mb-4 md:mb-0 md:border-l">
            <h3 className="text-xl text-white  my-2 font-heading semititulosblanco">SECUNDARIA</h3>
            <p className="text-gray-600 leading-relaxed parrafo px-8">Cuota mensual: $2,800.00</p>
          </div>
        </div>
        <p className="max-w-2xl mb-8 mx-auto text-center text-gray-200 leading-relaxed semititulosblanco py-6">Tenemos convenio con diferentes empresas</p>

          <div className="flex flex-wrap items-center justify-center -mx-8 mb-4">
  

          <Link class="inline-block py-4 px-8 leading-none text-white verde hover:bg-white hover:text-blue-900 rounded shadow" to="/empresas">Ver empresas</Link>

          </div>
      </section>
)