import React, {useState} from 'react'
import {Link} from 'react-router-dom'


import {  MdPhone, MdEmail, MdLocationOn, } from "react-icons/md";
import { IoLogoFacebook, IoLogoInstagram, IoLogoYoutube, IoLogoTwitter, IoLogoLinkedin, } from 'react-icons/io'

import {IconContext} from 'react-icons'


import logo from './img/logo.png'

export default function Nav () {
    return (
      <section className="py-4 px-4 ">
    <div className="flex flex-wrap -mx-4">
      <div className="lg:w-1/3 px-4 mb-4 lg:mb-0">
      <nav className="flex justify-center p-4 max-w-xs"><Link className="text-xl text-indigo-500 font-semibold" to="/"><img src={logo} alt="Liceo Pierre Bourdieu" width="500" /></Link></nav>
      </div>
      <div className="lg:w-2/3 px-6">

     
      <p className="parrafo text-blue-900">
      <IconContext. Provider value={{className:"inline-block mr-2 text-4xl verdeicono parrafo "}} >
         <MdLocationOn /> Fernández de Lizardi 706-A, Nueva Francisco I Madero, 42070 Pachuca Hgo.
      </IconContext.Provider></p>

      <p className="parrafo text-blue-900">
      <IconContext. Provider value={{className:"inline-block mr-2 text-4xl verdeicono "}} >
         <MdPhone />771-281-6398
      </IconContext.Provider></p>

      <p className="parrafo text-blue-900">
      <IconContext. Provider value={{className:"inline-block mr-2 text-4xl verdeicono"}} >
         <MdEmail /> admon@liceopb.edu.mx
      </IconContext.Provider></p>

      </div>
    </div>
    </section>
      
    )
}