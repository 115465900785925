import React, {useState} from 'react'
import img1 from './img/banner1.jpg'
import img2 from './img/banner2.jpg'
import img5 from './img/banner5.jpg'
import img6 from './img/banner6.jpg'


export default function Banner () {

  const [count, setCount] = useState(img1);

    return (
        <section className="bg-cover lg:bg-center " style={{backgroundImage: `url('${count}')`}}>
  <div className="relative py-8 md:py-24 px-16">
    <div className="absolute inset-0 bg-black opacity-25"></div>
    <div className="z-10 relative w-full">
      <div className="flex flex-col md:flex-row items-center">
        <div className="max-w-sm md:mr-auto text-center md:text-left text-white">
          
          <h2 className="text-4xl leading-tight titulosblanconosize font-heading">EL COLEGIO DE LOS </h2>
          <h1 className="text-6xl leading-tight titulosblanconosize font-heading">NIÑOS FELICES</h1>
          <p>___</p>
          <p>_____</p>
          <p>_______</p>
          <p>_________</p>
          <p>___________</p>

        </div>
        <div className="mt-8 md:mt-0">
          <button className="inline-flex md:flex items-center justify-center h-2 w-2 mr-3 mb-4 bg-indigo-500 rounded-full" onClick={() => setCount(img1)}></button>
          <button className="inline-flex md:flex items-center justify-center h-2 w-2 mr-3 mb-4 bg-white rounded-full" onClick={() => setCount(img2)}></button>
          <button className="inline-flex md:flex items-center justify-center h-2 w-2 mr-3 mb-4 bg-white rounded-full" onClick={() => setCount(img5)}></button>
          <button className="inline-flex md:flex items-center justify-center h-2 w-2 mr-3 mb-4 bg-white rounded-full" onClick={() => setCount(img6)}></button>
        </div>
      </div>
    </div>
  </div>
</section>
    )
}