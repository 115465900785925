import React from 'react'
import {Link} from 'react'

import arte1 from './img/arte/1.png'
import arte2 from './img/arte/2.jpg'
import arte3 from './img/arte/3.jpg'
import arte4 from './img/arte/4.jpg'
import arte5 from './img/arte/5.jpg'
import arte6 from './img/arte/6.jpg'

export default () => (
  <section className="py-8 px-4">
    <div className=" text-center  bg-white">
      <p className=" text-4xl font-normal py-6 titulos">Galeria de Arte</p>
      <p className="parrafo leading-relaxed">Trabajos de diversos alumnos en donde desarrollan habilidades motrices finas y gruesas.</p>
    </div>
    <div className="flex flex-wrap -mx-4 py-10 ">
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte2} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Alan Bello</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">3° Secundaria</p> 
        </div>
      </div>
    
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte3} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Ana Suarez</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">2° Secundaria</p> 
        </div>
      </div>

      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte4} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Javier Perez</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">1° Secundaria</p> 
        </div>
      </div>
    </div>
    
    <div className="flex flex-wrap -mx-4  ">
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte1} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Miguel Dominguez</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">3° Secundaria</p> 
        </div>
      </div>
    
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte5} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Juan Sosa</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">2° Secundaria</p> 
        </div>
      </div>

      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={arte6} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Pintura Oleo</p>
          <p className=" leading-tight mb-2 parrafo hover-target">Autor: Karen Santiago</p> 
          <p className=" leading-tight mb-2 parrafo hover-target">1° Secundaria</p> 
        </div>
      </div>
    </div>
  </section>
)