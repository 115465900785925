import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'

import logo from './logo.svg';
import './App.css';

import Calendar from './views/Calendar'
import CardNiveles from './views/CardNiveles'
import Arte from './views/Arte'
import Footer from './views/Footer'
import IntermedioBanner from './views/IntermedioBanner'
import Nav from './views/Nav'
import Contacto from './views/Contacto'
import Ventajas from './views/Ventajas'
import Instalaciones from './views/Instalaciones'
import Navdos from './views/Navdos'
import Navtres from './views/navtres'

import Modelo from './views/Modelo'
import Player from './views/Player'
import Feed from './views/Home'

import ListExample from './views/ListExample'
import GalleryArte from './views/GalleryArte'
import Nosotros from './views/Nosotros'
import Oferta from './views/Oferta'
import Comunidad from './views/Comunidad'


import Clases from './views/clasesvirtuales'
import Empresas from './views/Empresas'
import Presencial from './views/Presencial'
import Intervencion from './views/Intervencion';







export default function App() {


  return (
    <Router>

      <Nav />
      <Navtres />
 


      <Pag />

      <Footer />
      </Router>
  );
}

function Pag () {
  return (
    <Switch>
      <Route 
        path="/nosotros"
        render = {props => 
          <Nosotros />


        }
      />
      <Route 
      path="/oferta"
      render = {props => 
        <Oferta />


      }
    />
    <Route 
    path="/comunidad"
    render = {props => 
      <Comunidad />


    }
      />
      <Route 
      path="/arte"
      render = {props => 
        <GalleryArte />


      }
      />
      <Route 
        path="/clases"
        render = {props => 

          <Clases />
        }
      />
      <Route 
        path="/extracurricula"
        render = {props => 

          <Ventajas />

        }
      />
      <Route 
        path="/presencial"
        render = {props => 

          <Presencial />

        }
      />
      <Route 
        path="/intervencion"
        render = {props => 

          <Intervencion />

        }
      />
      <Route 
        path="/empresas"
        render = {props => 

          <Empresas />

        }
      />
      <Route 
        render = {props => 
          <Feed />
        }
        default
      />
      
    </Switch>
  )
}
