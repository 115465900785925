
import React from 'react'
import {Link} from 'react-router-dom'

import img from './img/seleccion/22.jpg'


export default () => (
  <div>


    <section class=" py-12 px-4 ">
    <h2 className="text-4xl mb-2 text-center titulos leading-tight font-heading">¿Cómo será el
    regreso a clases
    presenciales?</h2>
    <p className="text-center mb-12 text-blue-900 parrafo">A través de medidas de control que nos
    permitan garantizar un ambiente seguro.</p>
   
        <div class="flex flex-wrap  items-center text-center lg:text-left -mx-2">
          <div className="lg:w-1/2  px-2"><img className=" rotate-45" src={img} alt="" /></div>
          <div class="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0">
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">1. Instalaciones limpias</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Cada aula estará
              previamente desinfectada y limpia, para
              asegurar que los alumnos lleguen a un
              espacio seguro. Tomando en cuenta que el
              semáforo epidemiológico esté en verde.</p>
            </div>
            <div class="p-4 mb-4 rounded shadow-lg">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">2. Filtros sanitarios</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Habrá un comité
              encargado de dar la bienvenida a los alumnos
              y verificar su estado de salud diariamente,
              además de revisar que los alumnos cumplan
              con el equipo de protección y limpieza
              personal.</p>
          </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">3. Acciones de limpieza</h3>
              <p class="text-blue-900 leading-relaxed parrafo">
              Los docentes establecerán
acciones para garantizar la
limpieza de los alumnos y
dentro del salón de clases.
a) Limpieza de bancas
b) Lavado de manos al
terminar cada clase.
              </p>
            </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">4. Comité de monitores</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Serán los encargados
              de verificar que los
              procedimientos de limpieza
              y desinfección sean llevados
              de manera correcta y puntual.</p>
            </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">5. Descansos escalonados</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Esto permitirá a los alumnos
              disfrutar libremente de su
              receso, respetando la sana
              distancia entre compañeros</p>
            </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">6. Dispensadores de gel desinfectante, jabón y toallitas sanitarias</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Estarán señalizados y ubicados
              en diversos puntos de la
              escuela para el libre uso de
              toda la comunidad estudiantil.</p>
            </div>
          </div>
        </div>
      </section>
      </div>
)
