import React from 'react'
import {Link} from 'react-router-dom'

import img from './img/seleccion/soft.jpg'


export default () => (
  <div>


    <section class=" py-12 px-4 ">
    <h2 className="text-4xl mb-2 text-center titulos leading-tight font-heading">Clases virtulales</h2>
    <p className="text-center mb-12 text-blue-900 parrafo">Derivado de la problematica actual, se implementan acciones basadas en tecnologia.</p>
   
        <div class="flex flex-wrap items-center text-center lg:text-left -mx-2">
          <div class="lg:w-1/2 px-2"><img src={img} alt="" /></div>
          <div class="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0">
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading titulosverde">1. Clases virtuales</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Los maestros y alumnos trabajan de manera
              virtual desde casa, con apoyo tecnológico.</p>
            </div>
            <div class="p-4 mb-4 rounded shadow-lg">
              <h3 class="text-2xl mb-2 font-heading titulosverde">2. Plataformas educativas</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Se emplean plataformas como Google
              Classroom, donde los maestros asignan
              temas y actividades a realizar de manera
              complementaria.</p>
          </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading titulosverde">3. Horario de clases</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Preescolar y primaria 8:30 a 14:30, secundaria 8:00 a 15:30.</p>
            </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading titulosverde">4. Pausas activas</h3>
              <p class="text-blue-900 leading-relaxed parrafo">En el transcurso de las actividades se hacen pausas activas para un mejor rendimiento.</p>
            </div>
          </div>
        </div>
      </section>
      </div>
)