import React from 'react'

import Instalaciones from './Instalaciones'



import modelo from './img/modelo.jpg'


export default () => (
  <div>
  <div className="flex flex-wrap items-center text-center lg:text-left -mx-2 py-8 px-4">
    <div className="lg:w-1/3 px-2">
    <img className="rounded shadow-md" src={modelo} alt="" />
    </div>
    <div className="lg:w-2/3 px-2 lg:pl-16 mt-10 lg:mt-0 text-center">
      <h2 className="text-5xl mb-6 leading-tight font-heading  titulos">Modelo Educativo</h2>
      <p className="text-gray-700 leading-relaxed text-justify parrafo">
      El modelo educativo del Liceo Pierre Bourdieu está basado en
      un enfoque humanista y constructivista, éste último fundado en
      la teoría de Piaget. Buscando hacer niños felices, brindando
      conocimientos para enfrentar la vida, en un ámbito de respeto,
      así como la toma de decisiones, que impulsen a nuestros
      alumnos a romper con las estructuras heredadas, promoverlos
      como agentes de cambio social para una mejor calidad de vida,
      sin olvidar la parte cultural y tradicional que nos caracteriza.
      </p>
      <p className="text-gray-700 py-5 leading-relaxed text-justify semititulos">
      “El objetivo de mi trabajo es mostrar que la cultura y la
      educación, no son simples pasatiempos o influencias menores”.
      </p>
      <p className="py-10 text-gray-700 leading-relaxed text-right semititulos">Pierre Bourdieu</p>
    </div>
  </div>
    <section className=" px-16 ">
    <div className="flex flex-wrap -mx-6 ">
      <div className="lg:w-1/2 px-6 py-6 mb-6 lg:mb-0 verde">
        <h3 className="text-3xl mb-4 titulos">Misión</h3>
        <p className="text-gray-700 parrafo">
        Formar ciudadanos emprendedores, críticos y
libres, que se enfrenten al mundo con decisión, determinación y

empatía.
        </p>
      </div>
      <div className="lg:w-1/2 px-6 py-6 verdeclaro">
        <h3 className="text-3xl mb-4 titulos">Objetivo</h3>
        <p className="text-gray-700 parrafo">
        Es la formación de hombres y mujeres
íntegros, ofreciéndoles los conocimientos necesarios para la vida,
basándonos en valores imprescindibles para lograr trascender en

cualquier contexto social.
        </p>
      </div>
    </div>
    </section>

    <Instalaciones />
  </div>

)