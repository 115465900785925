import React from 'react'
import {Link} from 'react-router-dom'

import img from './img/seleccion/intervenciont.jpg'


export default () => (
  <div>
    <section class=" py-12 px-4 ">
    <h2 className="text-4xl mb-2 text-center titulos leading-tight font-heading">Intervención Educativa y Psicológica</h2>
    <p className="text-center mb-12 text-blue-900 parrafo">
    Nuestro equipo de docentes está conformado por profesionales con diversos perfiles,
    que permiten realizar un trabajo multidisciplinario colaborativo, para el óptimo
    desarrollo de nuestros NNA.
    </p>
        <div class="flex flex-wrap  items-center text-center lg:text-left -mx-2">
          <div className="lg:w-1/2  px-2"><img className=" rotate-45" src={img} alt="" /></div>
          <div class="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0">
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">01 ¿Por qué es importante</h3>
              <p class="text-blue-900 leading-relaxed parrafo">Para que la escuela se coviernta
              en un agente de cambio para la promoción del bienestar físico y psicológico
              de toda la comunidad edcucativa.
              </p>
            </div>
            <div class="p-4 mb-4 rounded shadow-lg">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">02 </h3>
              <p class="text-blue-900 leading-relaxed parrafo">
                Mejora del rendimiento académico al implementar un clima escolar positivo.
              </p>
          </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">03 </h3>
              <p class="text-blue-900 leading-relaxed parrafo">
              Promoción de una convivencia escolar saludable para la mejora en los procesos de aprendizaje 
              y motivación académica. 
              </p>
            </div>
            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">04</h3>
              <p class="text-blue-900 leading-relaxed parrafo">
              Planeación de estrategias para el incremento de conductas positivas en el aula y la disminución
              de conductas disruptivas.
              </p>
            </div>

            <div class="p-4 mb-4">
              <h3 class="text-2xl mb-2 font-heading semititulosverde">05</h3>
              <p class="text-blue-900 leading-relaxed parrafo">
              Intervención en la búsqueda de soluciones ante problemas de aprendizaje para garantizar 
              el éxito académico.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
)
