import React from 'react'
import {Link} from 'react-router-dom'

import farmamigo from './img/convenios/farma.jpg'
import farmasi from './img/convenios/farmasi.jpg'
import pasteko from './img/convenios/pasteko.jpg'
import wolf from './img/convenios/wolff.jpg'
import cuatro from './img/convenios/cuatro.jpg'
import farmapp from './img/convenios/farmapp.jpg'
import amigo from './img/convenios/amigo.jpg'

import uaeh from './img/convenios/uaeh.png'
import imss from './img/convenios/imss.png'
import poder from './img/convenios/poder.jpg'
import pachuca from './img/convenios/pachuca.png'
import ssh from './img/convenios/ssh.png'




export default () => (
 
    <section className="py-12 px-4 text-center  ">
    <h2 className="text-4xl mb-2 leading-tight py-4 titulos font-heading">Convenios</h2>
    <div className="max-w-xl mx-auto mb-4 text-blue-900 parrafo text-justify">En apoyo a las familias pachuqueñas el Liceo
    Pierre Bourdieu ha establecido una serie
    de tarifas en colegiaturas e inscripciones
    accesibles con el fin de apoyar la economía
    actual.</div>
    <div className="max-w-xl mx-auto mb-4 text-blue-900 parrafo text-justify ">De igual manera ha disminuido la lista de
    útiles y materiales para no generar gastos
    innecesarios y excesivos.</div>
    <div className="max-w-xl mx-auto mb-8 text-blue-900 parrafo text-justify">Las empresas participantes de estos
    convenios manejan tarífas con descuentos,
    que se hacen válidas con la presentación de
    una credencial de trabajador vigente o con
    un recibo de nómina por parte de la empresa.</div>
    <div className="max-w-xl mx-auto mb-8 text-blue-900 parrafo">Algunas de ellas son:</div>
    
    <div className="">
    <figure className="md:flex bg-gray-100  p-16 md:p-0">
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={pasteko} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={farmamigo} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={farmasi} alt="" width="384" height="512" />
    </figure>

    <figure className="py-8 md:flex bg-gray-100 p-8 md:p-0">
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={amigo} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={farmapp} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={ssh} alt="" width="350" height="350" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={uaeh} alt="" width="350" height="350" />
    </figure>

  
    <div className="py-8 md:flex bg-gray-100  p-8  md:p-0">
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={pachuca} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={wolf} alt="" width="384" height="512" />
    <img className=" w-48 h-32 md:w-64 md:h-auto  mx-auto" src={cuatro} alt="" width="384" height="512" />
    </div>

    <figure className=" py-8 md:flex bg-gray-100 p-8 md:p-0">
    <img className=" w-32 h-32 md:w-64 md:h-64  mx-auto" src={imss} alt=""  />
    <img className=" w-32 h-32 md:w-64 md:h-64  mx-auto" src={poder} alt=""  />

    </figure>
    </div>
    
    
    
  </section>
)

