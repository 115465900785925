import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import img from './img/1.jpg'

export default function Arte () {
    return (
        




      <section className="py-8 px-4">
      <div className="flex flex-wrap items-center text-center lg:text-left -mx-2">
        <div className="lg:w-1/2 px-2"><img className="rounded shadow-md" src={img} alt="" /></div>
        <div className="lg:w-1/2 px-2 lg:pl-16 mt-10 lg:mt-0 text-center">
          <h2 className="text-5xl mb-6 leading-tight font-heading  titulos">Galeria de Arte</h2>
    
          <p className="text-gray-700 leading-relaxed parrafo">Nos enfocamos en el desarrollo de habilidades motrices finas y gruesas, mediante actividades lúdicas.</p>
          <div className="mt-8"><Link className="nline-block py-4 px-8 leading-none  bg-white semititulos border-blue-900 border-4 hover:bg-blue-900 hover:text-white rounded shadow" to="/arte">Abrir galeria</Link></div>
        </div>
      </div>
      </section>



    )
}