import React, {useState} from 'react'


export default function Contacto () {
    return (
        <section className="py-4 px-4 ">
  <h2 className="text-8xl text-center mb-8 font-heading titulos">Contacto</h2>
  <div className="flex flex-wrap items-center -mx-6">
    <div className="w-full  px-6">
      <h3 className="text-2xl mb-2 font-heading text-center semititulos">Dejanos guiar a tus pequeños</h3>
      <p className="  mb-8 text-center parrafo">Estamos esperando responder a todas sus preguntas y consultas.</p>
      
    </div>
  </div>

</section>
    )
}