import React from 'react'
import {Link} from 'react-router-dom'
import modelo from './img/modelo.jpg'

export default () => (


  <section className="py-8 px-4 ">
  <div className="flex flex-wrap items-center text-center lg:text-left -mx-2 ">
    <div className="lg:w-1/3 px-2"><img className="rounded shadow-md" src={modelo} alt="" /></div>
    <div className="lg:w-2/3 px-2 lg:pl-16 mt-10 lg:mt-0 text-center">
      <h2 className="text-5xl mb-6 leading-tight font-heading  titulos">Modelo Educativo</h2>
      <p className="text-gray-700 leading-relaxed text-justify parrafo">
      El modelo educativo del Liceo Pierre Bourdieu está basado en
      un enfoque humanista y constructivista, éste último fundado en
      la teoría de Piaget. Buscando hacer niños felices, brindando
      conocimientos para enfrentar la vida, en un ámbito de respeto,
      así como la toma de decisiones, que impulsen a nuestros
      alumnos a romper con las estructuras heredadas, promoverlos
      como agentes de cambio social para una mejor calidad de vida,
      sin olvidar la parte cultural y tradicional que nos caracteriza.
      </p>
      <div className="mt-8 text-center"><Link className="inline-block py-4 px-8 leading-none text-blue-900 bg-white semititulos border-blue-900 border-4 hover:bg-blue-900 hover:text-white rounded shadow semititulos" to="/nosotros">Leer más</Link></div>

    </div>
  </div>
</section>


)