import React from 'react'
import canchas from './img/instalaciones/salon.jpg'
import patio from './img/instalaciones/patio.jpg'
import mesa from './img/instalaciones/mesa.jpg'
import quimica from './img/instalaciones/quimica.jpg'
import compu from './img/instalaciones/computo.jpg'
import artes from './img/instalaciones/artes.jpg'

export default () => (
    <section className="py-12 px-4">
  <h2 className="text-4xl text-center mb-4 font-heading titulos">Instalaciones</h2>
  <p className="max-w-2xl mb-8 mx-auto text-center text-gray-700 leading-relaxed">Contamos con instalaciones nuevas y diseñadas a los ambientes de aprendizaje de los pequeños.</p>
  <p className="max-w-2xl mb-8 mx-auto text-center text-gray-700 leading-relaxed">
  Se componen de una
construcción de 1600 metros
cuadrados, donde contamos
con: cafetería, laboratorio de ciencias, laboratorio de computo, salón de usos múltiples, área verde, salón de artes, cancha de fútbol, aula de maestros, amplio estacionamiento, equipos de apoyo audiovisual de última generación.
  </p>
  <div className="flex max-w-lg mb-12 mx-auto text-center border-b-2">
 
    
  </div>
  <div className="flex flex-wrap -mx-4 -mb-8">
    <div className="md:w-1/3 px-4 mb-8  hover-trigger hover:opacity-75">
      <img className="rounded shadow-md" src={canchas} alt="" />
      <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Salón de clases</p>
        </div>
    </div>
    <div className="md:w-1/3 px-4 mb-8 hover-trigger hover:opacity-75">
    <img className="rounded shadow-md" src={patio} alt="" />
    <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Explanada principal</p>
        </div>
    </div>
    <div className="md:w-1/3 px-4 mb-8 hover-trigger hover:opacity-75">
    <img className="rounded shadow-md" src={mesa} alt="" />
    <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Área de lectura</p>
        </div>
    </div>
    <div className="md:w-1/3 px-4 mb-8 hover-trigger hover:opacity-75">
    <img className="rounded shadow-md" src={quimica} alt="" />
    <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Laboratorio de ciencias</p>
        </div>
    </div>
    <div className="md:w-1/3 px-4 mb-8 hover-trigger hover:opacity-75">
    <img className="rounded shadow-md" src={compu} alt="" />
    <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Laboratorio de computo</p>
        </div>
    </div>
    <div className="md:w-1/3 px-4 mb-8 hover-trigger hover:opacity-75">
    <img className="rounded shadow-md" src={artes} alt="" />
    <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Salón de artes</p>
        </div>
    </div>
  </div>
</section>
)