import React from 'react'
import {Link} from 'react-router-dom'


export default () => (
<div>
<section className="py-8 px-4 azul">
        <div className="flex flex-wrap -mx-4 -mb-6 ">
          <div className="lg:w-1/3 px-4 mb-4 pl-32">
            <h3 className="text-xl my-2 font-heading semititulosblanco ">NOSOTROS</h3>
            <div className="flex flex-wrap items-center bg-white justify-between py-1" ></div>

            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/nosotros">Modelo educativo</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/nosotros">Políticas de la empresa</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/nosotros">Instalaciones</Link></p>

          </div>
          <div className="lg:w-1/3 px-4 mb-6 pl-32">
            <h3 className="text-xl   my-2 font-heading semititulosblanco ">OFERTA EDUCATIVA</h3>
            <div className="flex flex-wrap items-center bg-white justify-between py-1 " ></div>

            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/oferta">Preescolar</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/oferta">Primaria</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/nosotros">Secundaria</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/nosotros">Dpto. Intervención Psicológica</Link></p>

          </div>
          <div className="lg:w-1/3 px-4 mb-6 pl-32">
            <h3 className="text-xl my-2 font-heading shadow-md semititulosblanco ">COMUNIDAD</h3>
            <div className="flex flex-wrap items-center bg-white justify-between py-1 " ></div>

            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/">Avances</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/">Decálogo</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/">Triptico</Link></p>
            <p><Link className="text-gray-300 leading-relaxed  no-underline hover:underline parrafo" to="/">Entre aulas</Link></p>

          </div>
        </div>
      </section>
</div>


)