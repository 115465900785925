import React, {useState} from 'react'
import {IoLogoFacebook, IoLogoInstagram, IoLogoYoutube, IoLogoTwitter, IoLogoLinkedin} from 'react-icons/io'
import {IconContext} from 'react-icons'



export default function Footer () {
    return (
        <footer className="flex flex-wrap flex-col lg:flex-row items-center p-4 azul">

        <section className="py-8 px-4">
        <div className="flex flex-wrap -mx-6">
          <div className="lg:w-1/2 px-6 mb-6 lg:mb-0">
          <div className=" semititulosblanco">© 2020 Liceo Pierre Bourdieu</div>

          </div>
          <div className="lg:w-1/2 px-6">


         <span className="parrafo">
      <IconContext. Provider value={{className:"inline-block mr-2 text-4xl blancoicono"}} >
        <a target="blank" href="https://www.facebook.com/Liceo-Pierre-Bourdieu-100149768347495"><IoLogoFacebook /></a> <IoLogoYoutube /> 
      </IconContext.Provider></span>

          </div>
        </div>
        </section>

     


  <div class="btn-whatsapp">
<a href="https://api.whatsapp.com/send?phone=527713446841" target="_blank">
<img src="http://s2.accesoperu.com/logos/btn_whatsapp.png" alt="" />
</a>
</div>

</footer>


    )
}