import React, {useState} from 'react'

import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker,
    InfoWindow
} from 'react-google-maps'


const Map = (props) => {
    return (
        <GoogleMap 
        defaultZoom={19}
        defaultCenter={{lat: 20.117467, lng:-98.722711}}
        >
        {props.isMarkerShown && 
            <Marker 
            position={{ lat: 20.117467, lng:-98.722711 }}
            /> 
            
        }

        {props.InfoWindow && 
            <InfoWindow
               position={{lat:  20.117467,lng: -98.722711}}
            />   
         }

           
      </GoogleMap>
    )
}

export default withScriptjs(
    withGoogleMap(
        Map
    )
)