import React, { useState } from 'react'
import preescolar from './img/niveles/preescolar.jpg'
import uprimaria from './img/niveles/1prima.jpg'
import dprimaria from './img/niveles/2prima.jpg'
import usecu from './img/niveles/1secu.jpg'
import dsecu from './img/niveles/2secu.jpg'
import tsecu from './img/niveles/3secu.jpg'


export default () => {

  const [count, setCount] = useState(preescolar);

  return (
    <div className="">

    <section class="pt-8 px-4 pb-4">
    <h2 className="text-4xl mb-2 leading-tight font-heading titulos">Preescolar y Primaria</h2>
    <div className="flex flex-wrap -mx-4 text-center">

    <div className="w-full lg:w-1/3 px-4 mb-8">
          <div className="h-full pb-6 rounded shadow-md">
            <img className="mx-auto mb-4" src={preescolar} alt="" />
            <div className="px-8">
              <h3 className="text-xl font-heading font-semibold semititulos">Preescolar</h3>
              <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
              <div className="flex  py-3 my-4 mx-12 border-t border-b border-blue-700">
              <ul className=" ">
              <li className="parrafo text-blue-900">Lenguaje y comunicación</li>
              <li className="parrafo text-blue-900">Inglés</li>
              <li className="parrafo text-blue-900">Matemáticas</li>
              <li className="parrafo text-blue-900">Desarrollo físico y salud</li>
              <li className="parrafo text-blue-900">Desarrollo personal y social</li>
              <li className="parrafo text-blue-900">Exploración y conocimiento del mundo</li>
              <li className="parrafo text-blue-900">Expresión y apreciación artística</li>
              </ul>
              </div>
            </div>
          </div>
        </div>

      <div className="w-full lg:w-1/3 px-4 mb-8">
        <div className="h-full pb-6 rounded shadow-md">
          <img className="mx-auto mb-4" src={dprimaria} alt="" />
          <div className="px-8">
            <h3 className="text-xl font-heading font-semibold semititulos">Primaria baja</h3>
            <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
            <div className="flex justify-center py-3 my-4 mx-12 border-t border-b border-blue-700">
            <ul>
            <li className="parrafo text-blue-900">Español</li>
            <li className="parrafo text-blue-900">Inglés</li>
            <li className="parrafo text-blue-900">Matemáticas</li>
            <li className="parrafo text-blue-900">Exploración de la Naturaleza y Sociedad</li>
            <li className="parrafo text-blue-900">Formación Cívica y Ética</li>
            <li className="parrafo text-blue-900">Educación Física</li>
            <li className="parrafo text-blue-900">Expresión y apreciación artística</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
      <div className="w-full lg:w-1/3 px-4 mb-8">
        <div className="h-full pb-6 rounded shadow-md">
          <img className="mx-auto mb-4" src={uprimaria} alt="" />
          <div className="px-8">
            <h3 className="text-xl font-heading font-semibold semititulos">Primaria alta</h3>
            <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
            <div className="flex justify-center py-3 my-4 mx-12 border-t border-b border-blue-700" >
            <ul>
              <li className="parrafo text-blue-900">Español</li>
              <li className="parrafo text-blue-900">Inglés</li>
              <li className="parrafo text-blue-900">Matemáticas</li>
              <li className="parrafo text-blue-900">Ciencias Naturales</li>
              <li className="parrafo text-blue-900">Geografía</li>
              <li className="parrafo text-blue-900">Historia</li>
              <li className="parrafo text-blue-900">F.C y E</li>
              <li className="parrafo text-blue-900">Educación Física</li>
              <li className="parrafo text-blue-900">Expresión y apreciación artística</li>


              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </section>


  <section className="pt-8 px-4 pb-4">
    <h2 className="text-4xl mb-2 leading-tight font-heading titulos">Secundaria</h2>
    <div className="flex flex-wrap -mx-4 text-center">
      <div className="w-full lg:w-1/3 px-4 mb-8">
        <div className="h-full pb-6 rounded shadow-md">
          <img className="mx-auto mb-4" src={usecu} alt="" />
          <div className="px-8">
            <h3 className="text-xl font-heading font-semibold semititulos">1° Secundaria</h3>
            <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
            <div className="flex justify-center py-3 my-4 mx-12 border-t border-b border-blue-700">
            <ul>
            <li className="parrafo text-blue-900">Español</li>
            <li className="parrafo text-blue-900">Inglés</li>
            <li className="parrafo text-blue-900">Matemáticas</li>
            <li className="parrafo text-blue-900">Biología</li>
            <li className="parrafo text-blue-900">Tecnología</li>
            <li className="parrafo text-blue-900">Tutoría</li>
            <li className="parrafo text-blue-900">Formación Cívica y Ética</li>
            <li className="parrafo text-blue-900">Artes</li>
            <li className="parrafo text-blue-900">Educación Física</li>
            <li className="parrafo text-blue-900">Geografía de México y el mundo</li>

            </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/3 px-4 mb-8">
        <div className="h-full pb-6 rounded shadow-md">
          <img className="mx-auto mb-4" src={dsecu} alt="" />
          <div className="px-8">
            <h3 className="text-xl font-heading font-semibold semititulos">2° Secundaria</h3>
            <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
            <div className="flex justify-center py-3 my-4 mx-12 border-t border-b border-blue-700">
            <ul>
            <li className="parrafo text-blue-900">Español</li>
            <li className="parrafo text-blue-900">Inglés</li>
            <li className="parrafo text-blue-900">Matemáticas</li>
            <li className="parrafo text-blue-900">Física</li>
            <li className="parrafo text-blue-900">Tecnología</li>
            <li className="parrafo text-blue-900">Tutoría</li>
            <li className="parrafo text-blue-900">Historia</li>
            <li className="parrafo text-blue-900">Artes</li>
            <li className="parrafo text-blue-900">Educación Física</li>
            <li className="parrafo text-blue-900">Formación Cívica y Ética</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/3 px-4 mb-8">
        <div className="h-full pb-6 rounded shadow-md">
          <img className="mx-auto mb-4" src={tsecu} alt="" />
          <div className="px-8">
            <h3 className="text-xl font-heading font-semibold semititulos">3° Secundaria</h3>
            <span className="parrafo text-blue-900">Asignaturas ciclo escolar 2020-2021</span>
            <div className="flex justify-center py-3 my-4 mx-12 border-t border-b border-blue-700">
            <ul>
            <li className="parrafo text-blue-900">Español</li>
            <li className="parrafo text-blue-900">Inglés</li>
            <li className="parrafo text-blue-900">Matemáticas</li>
            <li className="parrafo text-blue-900">Química</li>
            <li className="parrafo text-blue-900">Tecnología</li>
            <li className="parrafo text-blue-900">Tutoría</li>
            <li className="parrafo text-blue-900">Historia</li>
            <li className="parrafo text-blue-900">Artes</li>
            <li className="parrafo text-blue-900">Educación Física</li>
            <li className="parrafo text-blue-900">Formación Cívica y Ética</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

</div>
  )
}
