import React, {useState} from 'react'
import ajedrez from './img/ajedrez.jpg'

export default function Ventajas () {
    return (
        <section className="py-8 px-4 bg-gray-400">
        <div className="text-4xl text-center text-blue-700 bg-gray-400">
        <p className="font-normal hover:font-bold py-10">Actividades extra curriculares</p>
        </div>
  <div className="max-w-2xl mx-auto">
    <img className="rounded shadow-md" src={ajedrez} alt="" />
    <div className="text-center mt-6">
      <button className="inline-flex items-center justify-center h-2 w-2 mr-2 bg-gray-800 rounded-full"></button>
      <button className="inline-flex items-center justify-center h-2 w-2 mr-2 bg-gray-500 rounded-full"></button>
      <button className="inline-flex items-center justify-center h-2 w-2 mr-2 bg-gray-500 rounded-full"></button>
      <button className="inline-flex items-center justify-center h-2 w-2 mr-2 bg-gray-500 rounded-full"></button>
      <button className="inline-flex items-center justify-center h-2 w-2 mr-2 bg-gray-500 rounded-full"></button>
    </div>
  </div>
</section>
    )
}