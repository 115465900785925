import React, {useState} from 'react'
import preescolar from './img/pre.jpg'
import primaria from './img/pri.jpg'
import secundaria from './img/sec.jpg'
import fondo from './img/doodles.svg'

import Card from './CardHover'

import {Link} from 'react-router-dom'



export default function CardNiveles () {
    return (
        <section className=" py-8 px-4 bg-cover lg:bg-center verdeclaro "  style={{backgroundImage: `url('${fondo}')`}}>
        <div className=" text-center ">
      <p className=" text-4xl font-normal  titulos">Oferta educativa</p>
    </div>
    <div className="flex flex-wrap -mx-4 py-6 ">
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={preescolar} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Preescolar</p>
          <p className=" leading-tight mb-2 p-2 text-justify parrafo bg-white hover-target">Nos enfocamos en el desenvolvimiento de habilidades motrices finas y gruesas, mediante actividades lúdicas.</p> 
          <p className=" leading-tight mb-2 p-2 text-justify parrafo bg-white hover-target">También trabajamos en las áreas socioemocionales, para desarrollar la seguridad, autoestima y autonomía de nuestros alumnos.</p> 
          <p className=" leading-tight mb-2 p-2 text-justify parrafo bg-white hover-target">Contamos con instalaciones nuevas y diseñadas a los ambientes de aprendizje de los pequeños.</p> 
          <p className=" leading-tight mb-2 p-2 text-justify parrafo bg-white hover-target">Nuestro enfoque está basado en el humanismo y constructivismo, centrado en los alumnos.</p> 
          <Link className="text-3xl leading-tight mb-2 text-white hover-target azul" to="/oferta">Ir</Link>

        </div>
      </div>
    
      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={primaria} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Primaria</p>
          <p className=" leading-tight p-2 mb-2 text-justify parrafo bg-white hover-target">Cuenta con la guía de maestros profesionales en constate capacitación para brindar el mejor acompañamiento a los alumnos, con base a los dinstintos tipos y ritmos de aprendizaje.</p> 
          <p className=" leading-tight p-2 mb-2 text-justify parrafo bg-white hover-target">Nuestro objetivo es hacer niños felices, a través de un trabajo tripartita (Profesores, alumnos y padres de familia), brindando conocimiento para enfrentar la vida, en un ámbito de respeto, así como el trabajo en la toma de decisiones, que impulsen a nuestros alumnos a romper con las estructuras
          heredadas, promoverlos como agentes de cambio social para una mejor calidad de vida, sin olvidar la parte cultural y tradicional que nos caracteriza. </p>
          <Link className="text-3xl leading-tight mb-2 text-white hover-target azul" to="/oferta">Ir</Link> 
        </div>
      </div>

      <div className="md:w-1/3 px-4 mb-8 md:mb-0  hover-trigger hover:opacity-75">
        <img className="rounded shadow-md fd-sh " src={secundaria} alt="" />
        <div className="z-10 w-full text-center ">
          <p className="text-3xl leading-tight mb-2 text-white hover-target azul">Secundaria</p>
          <p className=" leading-tight p-2 mb-2 text-justify parrafo bg-white hover-target">Nuestros profesores cuentan con perfiles profesionales acorde al grado y asignatura que imparten, con años de experiencia en la práctica docente.</p> 
          <p className=" leading-tight p-2 mb-2 text-justify parrafo bg-white hover-target">Están en constante actualización a través de diversos cursos y contenidos propios del modelo educativo.</p> 
          <p className=" leading-tight p-2 mb-2 text-justify parrafo bg-white hover-target">Impartimos clases de inglés, con el objetivo de volvernos a mediano plazo una escuela bilingüe. </p> 

          <p className=" leading-tight p-2 mb-2 parrafo bg-white hover-target">Contamos con clases de artes que está enfocada al 100% en incursionar a nuestros alumnos a la historia y práctica del arte. </p> 
          <Link className="text-3xl leading-tight mb-2 text-white hover-target azul" to="/oferta">Ir</Link>
        </div>
      </div>
    </div>


            
        </section>
    )
}